<template>
  <div v-if="entryForm">
    <!-- <button v-on:click='load_entry_data'>load data</button> -->
    <entry-form actionText= "create" @submitted="formSubmitted"></entry-form>
</div>
</template>
<script>
import EntryForm from 'components/super/EntryForm.vue';
import {ENV_URL, MAIN_URL, STRIPE_PUBLISHABLE} from '../../environment.js';
import axios from 'axios';



export default {
  name: 'directory-view',


  components: {
    EntryForm,
  },
  data () {

    return {
    data_request :[],
    post_data :[],
    addData:0,
    myentrydata : []
    }

  },
  created() {


    this.$store.dispatch('LOADING', true);

    

    const uri = ENV_URL + this.$route.path;
    console.log("the uri is" + uri);
    this.$store.dispatch('SUPER_ENTRIES_CREATE_TYPE', {uri: uri}).then(() => {

      this.$store.dispatch('LOADING', false);

    })

  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    entryForm() {
      return this.$store.state.entryForm;
    },
    entry() {
      return this.$store.state.entry;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted(e) {
      console.log('the hours are')
    
      console.dir(this.entryForm)
      var laravelAuthToken = localStorage.getItem('laravelAuthToken');
      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };
     
      const item_data = {
        entry_form_fields: this.entryForm.fields,
        categories: this.entry.categories,
        pageType : this.$route.params.type
      }

      console.log("item data: ");
      console.dir(item_data);
      
      // /super/contests/{contest}/entries/{id}/update
      let uri = ENV_URL + this.$route.path;
       console.log("the uri is" + uri);

       axios.post(uri, item_data, authHeaders).then((response) => {
        this.$store.dispatch('LOADING', false);
        alert('Your item was created!');
        console.dir(response)
        if(response.data.entry.id)
          this.$router.push({ path: '/super/listings/' + response.data.entry.id})
      })
      .catch((error) => {
        console.log('app error: ', error.message);
        this.$store.dispatch('LOADING', false);
        console.log("the errors is ")
        alert(error.message);
      })
      
    },
    add_to_post_data(){
      let attributes = ["name","type","title","value"];
      let field_object = {};
      for (let index = 0; index < attributes.length; index++) {
        field_object[attributes[index]] = arguments[index];
      }    
      return field_object;
    },

    // push_data(field,index){
    //   var title = field.Surgeon_FirstName + " " + field.Surgeon_LastName +", " + field.Credentials ;
    //   var ad_level = field.Enhanced.trim() !== "" ? 1 : 0;  
    //   var address = field.Address;
    //   this.post_data[index] = {"entry_form_fields":[],"categories":{"hcategory":[]}}
    //   var cat = {
    //     "term_id": 640,
    //     "name": "select-surgeon-bone-joint",
    //     "slug": "select-surgeon-bone-joint",
    //     "description": null,
    //     "count": 0,
    //     "taxonomy": "hcategory",
    //     "parent": 0,
    //     "id": 640,
    //     "level": 0,
    //     "attached": null,
    //     "full_name": "select-surgeon-bone-joint"
    //   }
    //   var fname =  this.add_to_post_data('first_name','field','First Name',field.Surgeon_FirstName);
    //   var lname =  this.add_to_post_data('last_name','field','Last Name',field.Surgeon_LastName);
    //   var cred = this.add_to_post_data('degree','field','Degree',field.Credentials);
    //   address = this.add_to_post_data('address','field','Address',address);
    //   title =  this.add_to_post_data('title','field','Title',title);
    //   var city = this.add_to_post_data('city','field','City',field.City)
    //   var phone = this.add_to_post_data('phone','field','Phone',field.Phone)
    //   var company = this.add_to_post_data('company','field','Company',field.Practice_Name)
    //   var state = this.add_to_post_data('state','field','State',field.State)
    //   var zip = this.add_to_post_data('zip','field','Zip',field.Zip)
    //   ad_level = this.add_to_post_data('ad_level','field','Ad Level',ad_level)
    //   let user = [fname,lname,cred,address,title,city,phone,company,state,zip,title,ad_level]
      
    //   this.post_data[index].entry_form_fields.push(user);
    //   this.post_data[index].categories.hcategory.push(cat);
    // },

    // load_entry_data(){
    //   for(var field in this.myentrydata){
    //     this.push_data(this.myentrydata[field],field)
    //   }

    //  this.save_bulk_data();
     
    // },

    // save_bulk_data(){
    //   console.log('saving data...' +  this.addData + ";")
    //   var laravelAuthToken = localStorage.getItem('laravelAuthToken');
    //   let uri = ENV_URL + this.$route.path;
    //   let authHeaders = {
    //     headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
    //   };
    //  this.post_data.forEach(element => {
    //   console.dir(element) 
    //  });
      
    // }

  }

}
</script>

<style lang="scss">

</style>
